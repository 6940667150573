import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './piezaCard.scss';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import AddToCartBtn from '../../../shared/AddToCartBtn';
import AddToFavoritosBtn from '../../../shared/AddToFavoritosBtn';
import Image from '../../../shared/Image';
import { saveFilters } from '../../../../redux/actions/filters';
import NoDataBtns from '../../../shared/NoDataBtns';

const PiezaCard = ({ pieza, filters, piezaIdToScroll, setPiezaIdToScroll, setIsPrevPagePartDetail }) => {
    const { nombre, imgs, marca, modelo, referencia, id, notapublica } = pieza;
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cart = useSelector(state => state.cart);
    const user = useSelector(state => state.auth);
    const cardRef = useRef(null);
    const [isModalSendEmailOpen, setIsModalSendEmailOpen] = useState(false);

    const handleNavigateToPartDetail = () => {
        dispatch(saveFilters(filters));
        navigate(`/detalle/${id}`);
    };

    useEffect(() => {
        if (pieza?.id === piezaIdToScroll) {
            const dims = cardRef.current.getBoundingClientRect();
            window.scrollTo({ top: dims.top - 217 });
            setPiezaIdToScroll(null);
            setIsPrevPagePartDetail(false);
        }
    }, [piezaIdToScroll]);

    return <div ref={cardRef} className={`pieza-card ${!isModalSendEmailOpen ? 'pieza-card-hover-active' : ''}` } onClick={handleNavigateToPartDetail}>
        <div className='pieza-card__img'>
            <Image foto={imgs?.split(',')[0]} altImg={`${nombre} ${marca} ${modelo}`} />
        </div>
        <div className='pieza-card__info'>
            <div>
                <h3 className='pieza-card__info--nombre'>{nombre}</h3>
                <p className='pieza-card__info--modelo'>{marca} {modelo}</p>
                <div className='pieza-card__info--containerdesk'>
                    <p className='pieza-card__info--containerdesk--labels'>
                        <span>OEM: <b>{referencia}</b></span>
                        <span>ID: <b>{id}</b></span>
                    </p>
                    {user?.role === 1 &&
                        <p className='pieza-card__info--id-ref'><span>ID Ref:</span> {pieza?.id_original}</p>
                    }
                    <div className='pieza-card__info--containerdesk--observaciones'>{t('catalogo-piezas.pieza-card.observaciones')} {notapublica || '-'}</div>
                </div>
            </div>
            <div>
                <h3 className='pieza-card__info--precio'>
                    <span>
                        {pieza?.precio_venta !== 0 ? (pieza?.precio_venta).toFixed(2) + '€ ' : t('catalogo-piezas.pieza-card.consultar')}
                    </span>
                    <span className={`pieza-card__info--precio--iva ${pieza?.precio_venta !== 0 ? '' : 'pieza-card__info--precio--consultar'}`}>
                        {pieza?.precio_venta !== 0
                            ? (user?.tipo_cliente === 2 ? '' : <>{t('catalogo-piezas.pieza-card.iva')}</>)
                            : t('catalogo-piezas.pieza-card.consultar-text')}
                    </span>
                </h3>
            </div>
        </div>
        <p className='pieza-card__disponible'><span>{t('catalogo-piezas.pieza-card.disponible')}</span> <span><b>exprés</b></span></p>
        <AddToFavoritosBtn
            pieza={pieza}
            classNameBtn='pieza-card__favorites'
            classNameDisabled='pieza-card__favorites--disabled'
        />
        {cart && cart?.piezas?.find(el => el?.pieza_integracion_id === pieza?.id) && <p className='pieza-card__en-carrito'>{t('catalogo-piezas.pieza-card.en-carrito')}</p>}
        {pieza?.precio_venta !== 0
            ? <AddToCartBtn
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                pieza={pieza}
                classNameBtn='pieza-card__cart'
                classNameDisabled='disabled-to-add'
                classNameSpinner='spinner-pieza-card' />
            : <NoDataBtns
                pieza={pieza}
                setIsModalSendEmailOpen={setIsModalSendEmailOpen}
                className='pieza-card__no-data-btns'
                filterValues={filters?.filterValues}
                searchParams={filters?.searchParams}
                isNotPrice={true} />
        }
    </div>;
};

PiezaCard.propTypes = {
    pieza: PropTypes.object.isRequired,
    filters: PropTypes.object.isRequired,
    piezaIdToScroll: PropTypes.any,
    setPiezaIdToScroll: PropTypes.func,
    setIsPrevPagePartDetail: PropTypes.func
};

export default PiezaCard;
