import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import WhatsAppSection from '../../shared/WhatsAppSection';
import { BiArrowBack } from 'react-icons/bi';

import './piezaDetalle.scss';
import ImgCarousel from './ImgCarousel';
import InfoDetalle from './InfoDetalle';
import { apiRequest } from '../../../services/api';
import SkeletonInfoDetalle from './SkeletonInfoDetalle';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const PiezaDetalle = () => {
    const { t } = useTranslation();
    const { piezaId } = useParams();
    const [infoPieza, setInfoPieza] = useState(null);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    const apiReqPiezaById = async () => {
        setIsLoading(true);
        const res = await apiRequest.stock.getPiezaById(piezaId);
        if (res?.success) {
            setInfoPieza(res.part);
        } else {
            toast.error(t('errors.1'));
        }
        return setIsLoading(false);
    };

    useEffect(() => {
        apiReqPiezaById();
        window.scrollTo(0, 0);
    }, [piezaId]);

    return <div className='pieza-detalle'>
        <h1>{t('catalogo-piezas.title')}</h1>
        <h2>{t('catalogo-piezas.subtitle')}</h2>
        <BiArrowBack className='pieza-detalle__back' onClick={() => navigate(-1)}/>
        <div className='pieza-detalle__container'>
            <ImgCarousel
                fotos={infoPieza?.imgs?.split(',')}
                isLoading={isLoading}
                altImg={`${infoPieza?.nombre} ${infoPieza?.marca} ${infoPieza?.modelo}`}
            />
            {!isLoading
                ? <InfoDetalle pieza={infoPieza}/>
                : <SkeletonInfoDetalle/>}
        </div>
        <WhatsAppSection className='pieza-detalle__whatsapp'/>
    </div>;
};

export default PiezaDetalle;
